<script setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const stack = ref([
    {
        name: t('landing_page.testimonials.list.one.name'),
        location: t('landing_page.testimonials.list.one.location'),
        testimonial: t('landing_page.testimonials.list.one.testimonial'),
        bgClass: 'bg-contrast-info-100',
    },
    {
        name: t('landing_page.testimonials.list.two.name'),
        location: t('landing_page.testimonials.list.two.location'),
        testimonial: t('landing_page.testimonials.list.two.testimonial'),
        bgClass: 'bg-contrast-info',
    },
    {
        name: t('landing_page.testimonials.list.three.name'),
        location: t('landing_page.testimonials.list.three.location'),
        testimonial: t('landing_page.testimonials.list.three.testimonial'),
        bgClass: 'bg-contrast-primary',
    },
    {
        name: t('landing_page.testimonials.list.four.name'),
        location: t('landing_page.testimonials.list.four.location'),
        testimonial: t('landing_page.testimonials.list.four.testimonial'),
        bgClass: 'bg-primary',
    },
    {
        name: t('landing_page.testimonials.list.five.name'),
        location: t('landing_page.testimonials.list.five.location'),
        testimonial: t('landing_page.testimonials.list.five.testimonial'),
        bgClass: 'bg-contrast-primary-100',
    },
]);

// Rotate the stack when clicked
const rotateStack = () => {
    stack.value.push(stack.value.shift());
};

const getCardStyle = (index) => {
    return {
        zIndex: stack.value.length - index,
        transform: `translateY(${index * 2}rem)`
    };
};
</script>

<template>
    <div class="stack cursor-pointer w-full" @click="rotateStack">
        <div
            v-for="(card, index) in stack"
            :key="index"
            :class="[
        'flex flex-col text-accent px-4 sm:px-16 py-8 lg:py-16 rounded-2xl transition-all duration-100 !opacity-100',
        card.bgClass,
      ]"
            :style="getCardStyle(index)"
        >
            <div class="text-2xl font-bold mb-1">
                {{ card.name }}
            </div>
            <div class="mb-6">
                {{ card.location }}
            </div>
            <div>
                {{ card.testimonial }}
            </div>
            <div class="text-right mt-4">
                <i class="fal fa-long-arrow-right text-3xl"></i>
            </div>
        </div>
    </div>
</template>
