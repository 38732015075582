<script setup>
import LandingPageLayout from '@/Layouts/LandingPageLayout.vue';
import LandingPageSection from '@/Components/LandingPageSection.vue';
import ApplicationLogo from '@/Components/ApplicationLogo.vue';
import FeatureCard from '@/Components/FeatureCard.vue';
import featureMessages from '@/../images/feature-cards/feature-messages.png';
import featureTreasures from '@/../images/feature-cards/feature-treasures.png';
import featureDocuments from '@/../images/feature-cards/feature-documents.png';
import featureSocials from '@/../images/feature-cards/feature-socials.png';
import featureEmergencyCards from '@/../images/feature-cards/feature-emergency-cards.png';
import impressionOne from '@/../images/impression-one.png';
import impressionTwo from '@/../images/impression-two.png';
import { Link } from '@inertiajs/vue3';
import ButtonIcon from '@/Components/ButtonIcon.vue';
import { useI18n } from 'vue-i18n';
import FaqCollapse from '@/Components/FaqCollapse.vue';
import Testimonials from '@/Components/Testimonials.vue';

const { t } = useI18n();

const faqs = [
    {
        question: t('landing_page.faq.list.one.question'),
        answer: t('landing_page.faq.list.one.answer'),
    },
    {
        question: t('landing_page.faq.list.two.question'),
        answer: t('landing_page.faq.list.two.answer'),
    },
    {
        question: t('landing_page.faq.list.three.question'),
        answer: t('landing_page.faq.list.three.answer'),
    },
]

</script>

<template>
    <LandingPageLayout>
        <LandingPageSection class="mb-32">
            <div class="flex flex-col lg:flex-row pt-8 lg:pt-0 relative">
                <div class="w-full px-4 sm:px-0 content-center">
                    <span class="px-3 py-1 border border-primary font-bold text-primary rounded-full text-sm mb-4">
                        {{ $t('landing_page.welcome.label') }}
                    </span>

                    <h1 class="font-bold mb-1 text-4xl lg:text-5xl mt-6">
                        {{ $t('landing_page.welcome.heading_one') }}
                    </h1>

                    <h1 class="font-bold lg:mb-3 text-4xl lg:text-5xl inline-block me-2">
                        {{ $t('landing_page.welcome.heading_two') }}
                    </h1>

                    <span class="inline-block">
                        <ApplicationLogo type="primary" h-class="h-9 lg:h-11 -mb-0.5"></ApplicationLogo>
                    </span>

                    <p class="text-pretty text-accent mb-6 mt-3">
                        {{ $t('landing_page.welcome.content') }}
                    </p>

                    <div class="flex flex-col lg:flex-row gap-3 mb-6">
                        <div>
                            <Link
                                :href="route('register')"
                                class="btn btn-warning btn-block"
                            >
                                {{ $t('landing_page.welcome.action_register') }}
                            </Link>
                        </div>
                        <div>
                            <a href="#functions" class="btn btn-ghost text-warning btn-block">
                                {{ $t('landing_page.welcome.action_discover') }}
                                <ButtonIcon orientation="right" icon="fa-arrow-right"></ButtonIcon>
                            </a>
                        </div>
                    </div>

                    <div class="flex flex-col lg:flex-row gap-3 text-sm">
                        <div class="mr-0 lg:mr-3">
                            <ButtonIcon icon="fa-user-check text-primary"></ButtonIcon>
                            {{ $t('landing_page.welcome.side_note_one') }}
                        </div>
                        <div>
                            <ButtonIcon icon="fa-server text-primary"></ButtonIcon>
                            {{ $t('landing_page.welcome.side_note_two') }}
                        </div>
                    </div>
                </div>
                <div class="w-full">
                    <img :src="impressionTwo" alt="Start with afterlife" class="rounded-2xl">
                </div>
            </div>
        </LandingPageSection>


        <LandingPageSection class="text-center text-accent font-bold text-5xl max-w-md mx-auto mb-10">
            <span id="functions" class="me-2">
                {{ $t('landing_page.functions.heading') }}
            </span>
            <span class="inline-block">
                <ApplicationLogo type="primary" h-class="h-11 -mb-0.5"></ApplicationLogo>
            </span>
        </LandingPageSection>

        <LandingPageSection>
            <FeatureCard
                id="messages"
                :title="$t('landing_page.functions.messages.heading')"
                :content="$t('landing_page.functions.messages.content')"
                class="mb-6"
                :feature-image="featureMessages"
                :link="route('features.messages')"
            >
            </FeatureCard>

            <FeatureCard
                id="treasures"
                :title="$t('landing_page.functions.treasures.heading')"
                :content="$t('landing_page.functions.treasures.content')"
                class="mb-6"
                bg-class="bg-contrast-info"
                :first-order-image="true"
                :feature-image="featureTreasures"
                :link="route('features.treasures')"
            >
            </FeatureCard>

            <FeatureCard
                id="documents"
                :title="$t('landing_page.functions.documents.heading')"
                :content="$t('landing_page.functions.documents.content')"
                class="mb-6"
                bg-class="bg-contrast-primary"
                :first-order-image="true"
                :feature-image="featureDocuments"
                badge-text="Coming soon"
            >
            </FeatureCard>

            <FeatureCard
                id="socials"
                :title="$t('landing_page.functions.socials.heading')"
                :content="$t('landing_page.functions.socials.content')"
                class="mb-6"
                bg-class="bg-contrast-base"
                :feature-image="featureSocials"
                badge-text="Coming soon"
            >
            </FeatureCard>

            <FeatureCard
                id="ec"
                :title="$t('landing_page.functions.emergency_cards.heading')"
                :content="$t('landing_page.functions.emergency_cards.content')"
                class="mb-40"
                bg-class="bg-contrast-warning"
                :feature-image="featureEmergencyCards"
                badge-text="Coming soon"
            >
            </FeatureCard>
        </LandingPageSection>

        <LandingPageSection bg-type-class="bg-primary" class="mb-40">
            <div class="flex flex-col lg:flex-row px-4 sm:px-0 py-24 gap-16 lg:gap-32">
                <div class="w-full lg:w-1/3">
                    <h3 id="faq" class="font-bold mb-3 text-5xl lg:text-6xl text-primary-content">
                        {{ $t('landing_page.faq.heading') }}
                    </h3>
                    <p class="text-pretty text-primary-content mb-6">
                        {{ $t('landing_page.faq.content') }}
                    </p>
                    <a
                        :href="route('help-center')"
                        class="btn btn-warning w-full lg:w-auto"
                        target="_blank"
                    >
                        {{ $t('landing_page.faq.action') }}
                    </a>
                </div>
                <div class="w-full lg:w-2/3">
                    <FaqCollapse
                        v-for="(faq, index) in faqs"
                        :key="index"
                        :question="faq.question"
                        :answer="faq.answer"
                        :is-visible="index === 0"
                        class="mb-4 text-primary-content"
                    >
                    </FaqCollapse>
                </div>
            </div>
        </LandingPageSection>

        <LandingPageSection>
            <h4 class="text-center text-accent font-bold text-5xl mb-10">
                {{ $t('landing_page.testimonials.heading') }}
            </h4>

            <div class="max-w-2xl mx-auto mb-64">
                <Testimonials></Testimonials>
            </div>

        </LandingPageSection>

        <LandingPageSection class="mb-40">
            <div class="flex flex-col lg:flex-row bg-contrast-primary rounded-2xl">
                <div class="w-full px-4 sm:px-16 py-8 content-center">
                    <h3 class="font-bold mb-3 text-4xl text-primary">
                        {{ $t('landing_page.call_to_action.heading') }}
                    </h3>
                    <p class="text-pretty text-accent mb-6">
                        {{ $t('landing_page.call_to_action.content') }}
                    </p>
                    <Link
                        :href="route('register')"
                        class="btn btn-warning w-full lg:w-auto"
                    >
                        {{ $t('landing_page.call_to_action.action') }}
                    </Link>
                </div>
                <div class="w-full">
                    <img :src="impressionOne" alt="Impression Old and Young" class="rounded-2xl">
                </div>
            </div>
        </LandingPageSection>

    </LandingPageLayout>
</template>

<style scoped>

</style>
