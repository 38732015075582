<script setup>
import { ref } from 'vue';

const props = defineProps({
    isVisible: {
        type: Boolean,
        default: false
    },
    question: {
        type: String,
        required: true
    },
    answer: {
        type: String,
        required: true
    }
})

const visible = ref(props.isVisible);

const toggle = () => {
    visible.value = !visible.value;
}

</script>

<template>
    <div class="cursor-pointer" @click="toggle()">
        <div class="flex items-center gap-3">
            <div>
                <i v-if="!visible" class="fad fa-plus-circle"></i>
                <i v-if="visible" class="fad fa-minus-circle"></i>
            </div>
            <div class="font-bold text-lg">
                {{ props.question }}
            </div>
        </div>

        <div v-if="visible" class="text-pretty px-6 py-6">
            {{ props.answer }}
        </div>
    </div>
</template>

<style scoped>

</style>
